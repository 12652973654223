import type * as Apollo from '@apollo/client'
import type { GroupBy } from '#board/constant'
import {
  BOARD_SETTINGS_PACKS_QUERY,
  BOARD_MEMBER_OPTIONS_QUERY,
  ADD_UPDATE_BOARD_MUTATION,
  TOGGLE_BOARD_STAR_MUTATION,
  GROUP_BY_BOARD_MUTATION,
  UPDATE_TASK_VIEW_OPTIONS_MUTATION,
  SET_ACTIVE_BOARD_MUTATION,
  COUNT_BOARDS_QUERY,
  LEAVE_BOARD_MUTATION,
  JOIN_BOARD_MUTATION,
  CLOSE_BOARD_MUTATION,
  OPEN_BOARD_MUTATION,
  BACKGROUND_PHOTO_QUERY,
  BOARD_FIELDS_QUERY,
  EXPORT_BOARD_MUTATION,
  PREDICT_FIELD_TYPES_FOR_IMPORT_MUTATION,
  IMPORT_TASK_MUTATION,
  UPDATE_CUSTOM_BACKGROUND_MUTATION,
} from '#board/schema'
import type {
  BaseBoard,
  BoardSettingsPacks,
  CreateBoardParams,
  UnsplashPhotoType,
  PredictedFieldType,
} from '#board/types'
import { BOARD_MEMBER_QUERY } from '#auth/schema'

import { WORKSPACE_LIST_BOARDS_QUERY } from '#workspace/schema'
import type { Field } from '#field/types'
import { BOARD_DATA_LOADER_QUERY } from '#board/loader_schema'

export const useBoardDataQuery = (boardId: string, options = {}) => {
  return useQuery(
    BOARD_DATA_LOADER_QUERY,
    {
      boardId,
    },
    options
  )
}

export const useBoardDataLazyQuery = (boardId: string, options = {}) => {
  return useLazyQuery(
    BOARD_DATA_LOADER_QUERY,
    {
      boardId,
    },
    options
  )
}

export const useBoardListsSettingsPacksQuery = (
  filter: Record<string, string>
) => {
  const result = useQuery<BoardSettingsPacks>(BOARD_SETTINGS_PACKS_QUERY, {
    filter,
  })
  return { ...result }
}

export const useBoardMemberOptionsQuery = (boardId: string) => {
  const result = useQuery<{
    boardMemberOptions: { star: boolean; viewOptions: []; groupBy: string }
  }>(BOARD_MEMBER_OPTIONS_QUERY, {
    boardId,
  }, {
    fetchPolicy: 'cache-first',
  })
  return { ...result }
}

export const useBoardFulfilledFieldsLazyQuery = (boardId: string) => {
  return useLazyQuery<{ boardFulfilledFields: Field[] }>(BOARD_FIELDS_QUERY, {
    boardId,
  })
}

export const useBoardFulfilledFieldsQuery = (boardId: string) => {
  return useQuery<{ boardFulfilledFields: Field[] }>(BOARD_FIELDS_QUERY, {
    boardId,
  })
}

export const useCreateBoardMutation = (params: CreateBoardParams) => {
  const { client } = useApolloClient()

  return useMutation<{ addUpdateBoard: { board: BaseBoard } }>(
    ADD_UPDATE_BOARD_MUTATION,
    {
      variables: {
        input: params,
      },
      async onQueryUpdated(observableQuery) {
        client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'workspaceBoards' })
        observableQuery.refetch()
      },
    }
  )
}

export const useUpdateBoardMutation = () => {
  return useMutation<{ addUpdateBoard: { board: BaseBoard } }>(
    ADD_UPDATE_BOARD_MUTATION,
    {
      errorPolicy: 'ignore',
    }
  )
}

export const useToggleBoardStarMutation = (boardId: string, star: boolean) => {
  return useMutation(TOGGLE_BOARD_STAR_MUTATION, {
    variables: {
      boardId,
      star,
    },
  })
}

export const useGroupByBoardMutation = (boardId: string, groupBy: GroupBy) => {
  return useMutation(GROUP_BY_BOARD_MUTATION, {
    variables: {
      boardId,
      groupBy,
    },
  })
}

export const useSetActiveBoardMutation = (boardId: string) => {
  return useMutation(SET_ACTIVE_BOARD_MUTATION, {
    variables: {
      boardId,
    },
  })
}

export const useUpdateTaskViewOptionsMutation = (
  boardId: string,
  viewOptions: string
) => {
  return useMutation(UPDATE_TASK_VIEW_OPTIONS_MUTATION, {
    variables: {
      boardId,
      viewOptions,
    },
  })
}

export const useLazyBackgroundPhotosQuery = (
  filter: {
    page?: number
    perPage?: number
    search?: string
  },
  options: Apollo.OperationVariables = {}
) => {
  return useLazyQuery<{ backgroundPhotos: UnsplashPhotoType[] }>(
    BACKGROUND_PHOTO_QUERY,
    filter,
    {
      ...options,
      fetchPolicy: 'cache-first',
    },
  )
}

export const useCountBoardsQuery = (workspaceId: string) => {
  return useQuery<{ countBoards: number }>(COUNT_BOARDS_QUERY, {
    workspaceId: workspaceId,
  })
}

// Hook for Open Board Mutation
export const useOpenBoardMutation = (boardId: string) => {
  return useMutation<{ openBoard: { board: BaseBoard } }>(OPEN_BOARD_MUTATION, {
    variables: {
      boardId,
    },
  })
}

// Hook for Close Board Mutation
export const useCloseBoardMutation = (
  boardId: string,
  hardDelete: boolean = false
) => {
  const { client } = useApolloClient()

  return useMutation<{
    closeBoard: { board: BaseBoard; hardDeleted: boolean }
  }>(CLOSE_BOARD_MUTATION, {
    variables: {
      boardId,
      hardDelete,
    },
    async onQueryUpdated(observableQuery) {
      if (hardDelete) {
        client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'workspaceBoards' })
        observableQuery.refetch()
      }
    },
  })
}

// Hook for Join Board Mutation
export const useJoinBoardMutation = (boardId: string) => {
  const { currentWorkspace } = useWorkspaceSharedState()
  return useMutation<{
    joinBoard: {
      success: boolean
    }
  }>(JOIN_BOARD_MUTATION, {
    variables: {
      boardId,
    },
    refetchQueries: [
      {
        query: BOARD_MEMBER_QUERY,
        variables: { boardId },
      },
      {
        query: WORKSPACE_LIST_BOARDS_QUERY,
        variables: {
          filter: { workspaceId: currentWorkspace.value.id },
          workspaceId: currentWorkspace.value.id,
        },
      },
    ],
  })
}

// Hook for Leave Board Mutation
export const useLeaveBoardMutation = (boardId: string) => {
  return useMutation<{
    leaveBoard: {
      success: boolean
      error: string
    }
  }>(LEAVE_BOARD_MUTATION, {
    variables: {
      boardId,
    },
    refetchQueries: [
      {
        query: BOARD_MEMBER_QUERY,
        variables: { boardId },
      },
    ],
    async onQueryUpdated() {
      // Refetch boardMember
      const { refetchBoardMember } = useBoardMemberSharedState()
      refetchBoardMember(boardId)

      // Clear list board members cache
      removeListBoardMembersCache(boardId)
    },
  })
}

export const useExportBoardMutation = () => {
  return useMutation<{
    exportBoard: {
      success: boolean
      downloadUrl: string
      isSendViaEmail: boolean
      error: string
    }
  }>(EXPORT_BOARD_MUTATION)
}

export const usePredictFieldTypesForImportMutation = () => {
  return useMutation<{
    predictFieldTypeImport: {
      success: boolean
      predictedFields: PredictedFieldType[]
      ignoreFields: string[]
    }
  }>(PREDICT_FIELD_TYPES_FOR_IMPORT_MUTATION)
}

export const useImportTaskMutation = () => {
  return useMutation<{
    importTask: {
      success: boolean
    }
  }>(IMPORT_TASK_MUTATION)
}

export const useUpdateCustomBackgroundMutation = () => {
  return useMutation<{ updateCustomBackground: { board: BaseBoard } }>(
    UPDATE_CUSTOM_BACKGROUND_MUTATION,
  )
}
